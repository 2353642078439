import { collection, query, onSnapshot, where, orderBy, doc, getDoc, getDocs, limit, startAfter, serverTimestamp, addDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { HEROKU_AGROLINK_URL, HEROKU_FOCUSED_ANALYST_EXTRACTRANSACTIONS_URL, HEROKU_FOCUSED_ANALYST_RELATIONSHIP_URL, HEROKU_FOCUSED_ANALYST_ACCEPTED_URL, HEROKU_INVOICE_SYNGENTA_TOTAL_URL, HEROKU_FOCUSED_ANALYST_CONSUPTION_URL, HEROKU_FOCUSED_ANALYST_CUSTOMERS_URL, HEROKU_GET_PERFORMANCE_URL, HEROKU_POST_COMPLETED_TRANSFER, HEROKU_GET_USER_BY_DOC, HEROKU_GET_BALANCE_TRANSFER_POINTS_URL, HEROKU_FAVORITE_ACCOUNTS, HEROKU_CREATE_POINT_GROUP, HEROKU_VALIDATE_USER, HEROKU_ADD_AND_INVITE, HEROKU_IS_IN_A_GROUP, HEROKU_GET_INVITES, HEROKU_ACCEPT_INVITE, HEROKU_SEARCH_CONTACT, HEROKU_GET_GROUP_DATA, HEROKU_INVITE_CONTACT, HEROKU_CHANGE_GROUP_NAME, HEROKU_LEAVE_GROUP, HEROKU_REMOVE_FROM_GROUP, HEROKU_END_GROUP, HEROKU_PROMOTE_TO_LEADER, HEROKU_REJECT_INVITE, HEROKU_GET_REGULATIONS, HEROKU_GET_RECORD_TYPE, HEROKU_GET_PROFILES_BY_USER_GROUP, HEROKU_GET_PERFORMANCE_SALES_TEAM_URL, HEROKU_GET_PERFORMANCE_SALES_TEAM_ANTICIPATION_URL, HEROKU_GET_DISTRIBUTORS_URL, HEROKU_GET_USER_INFO_BEFORE_LOGIN, HEROKU_REMOVE_FAVORITE_ACCOUNT, HEROKU_GROUP_HISTORY, HEROKU_CAMPAIGNS, HEROKU_CAMPAIGNS_ENROLLMENT, HEROKU_CAMPAIGNS_RESULTS, HEROKU_CAMPAIGNS_MY_NUMBERS, HEROKU_VERIFY_SWEEPSTAKE_IDS, HEROKU_GENERIC_INFO, HEROKU_GET_USER_REFERRAL_CODE, HEROKU_GET_ACESSA_PLUS_PARTIAL_CONSUMPTION, HEROKU_GET_PARTICIPANT_LOANS } from './URLconsts';
import moment from 'moment';
import { getAuth } from 'firebase/auth';

export default class DataHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/DataHelper/config.js`).then((importedFile) => {
      this.customCfg = importedFile?.default;
    });
  }

  setUserHelper(userHelper) {
    this.userHelper = userHelper;
  }

  setApiService(apiService) {
    this.api = apiService
  }

  getList(snapshot) {
    let list = [];

    snapshot.forEach((doc) => {
      list.push(doc.data());
    })

    return list;
  }

  async snapChallenges(callback) {
    let challenges = [];
    let userProgresses = [];
    let challengesQ = query(collection(this.db, '/HowToEarn/Progress/records/'));
    let unsub = [];

    unsub['challenges'] = onSnapshot(challengesQ, (snapshot) => {
      challenges = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges, userProgresses);
      callback(arrangedChallengeList);
    })

    // Busca progressos do usuário
    let userFirebaseId = await this.userHelper.getUserFirebaseId();

    let userProgressesQ = query(collection(this.db, '/Users/' + userFirebaseId + '/CampaignProgress/'));
    unsub['user_progresses'] = onSnapshot(userProgressesQ, (snapshot) => {
      userProgresses = this.getList(snapshot);
      let arrangedChallengeList = this.arrangeChallengeList(challenges, userProgresses);
      callback(arrangedChallengeList);
    })

    return unsub;
  }
  arrangeChallengeList(challenges, userProgresses) {
    let objectOutput = {};

    challenges.forEach((challenge) => {
      if (!objectOutput[challenge.Id]) objectOutput[challenge.Id] = {};
      objectOutput[challenge.Id] = challenge;
    })

    userProgresses.forEach((userProgress) => {
      if (!objectOutput[userProgress.CampaignItem__c]) objectOutput[userProgress.CampaignItem__c] = {};
      objectOutput[userProgress.CampaignItem__c].userProgress = userProgress;
    })

    let arrayOutput = [];

    Object.entries(objectOutput).forEach((object) => {
      arrayOutput.push(object[1]);
    })

    return arrayOutput;
  }
  snapMilestones(callback) {
    let milestonesQ = query(collection(this.db, 'Milestone'), where('Season__c', '==', global.season), orderBy('Points__c'));

    let unsub = onSnapshot(milestonesQ, (snapshot) => {
      let milestones = this.getList(snapshot);
      callback(milestones);
    })

    return { milestones: unsub }
  }
  snapHowToEarns(callback) {
    let simplePointsQ = query(collection(this.db, '/HowToEarn/SimplePoint/records/'));
    let pointsPerDollarQ = query(collection(this.db, '/HowToEarn/PointPerDollar/records/'));
    let simplePoints = [];
    let pointsPerDollar = [];
    let userData;

    let unsub = {};

    unsub = this.userHelper.snapUser((data) => {
      userData = data;
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    });

    unsub['simple_points'] = onSnapshot(simplePointsQ, (snapshot) => {
      simplePoints = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    })

    unsub['points_per_dollar'] = onSnapshot(pointsPerDollarQ, (snapshot) => {
      pointsPerDollar = this.getList(snapshot);
      let arrangedHowToEarnList = this.arrangeHowToEarnList(simplePoints, pointsPerDollar, userData);
      callback(arrangedHowToEarnList);
    })

    return unsub;
  }
  async getReferFriendDoc() {
    let simplePointsQ = query(collection(this.db, '/HowToEarn/SimplePoint/records/'));
    let simplePointsDoc = await getDocs(simplePointsQ);
    let out = null;

    if (simplePointsDoc.docs.length) {
      simplePointsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData?.HowToEarn__r?.AppScreen__c === 'ReferFriend') {
          out = docData.HowToEarn__r;
          return false;
        }

        return true;
      })
    }

    return out;
  }
  arrangeHowToEarnList(simplePoints, pointsPerDollar, userData) {
    let arrayOutput = [];

    let allPoints = [...simplePoints, ...pointsPerDollar];

    allPoints.forEach((point) => {
      if (point.HowToEarn__r) {
        const howToEarn = point.HowToEarn__r;

        const loyaltyVisibleString = howToEarn.LoyaltyVisible__c ? howToEarn.LoyaltyVisible__c : '';
        const loyaltyVisibleSet = loyaltyVisibleString.split(';');

        const milestoneVisibleString = howToEarn.MilestoneVisible__c ? howToEarn.MilestoneVisible__c : '';
        const milestoneVisibleSet = milestoneVisibleString.split(';');

        if (
          (loyaltyVisibleString.length === 0 || (userData && loyaltyVisibleSet.includes(userData.LoyaltyCategory__c)))
          // Removido no Syngenta
          // && (milestoneVisibleString.length === 0 || (userData && milestoneVisibleSet.includes(userData.MilestoneCategory__c)))
        ) {
          arrayOutput.push(point);
        }
      }
    })

    return arrayOutput;
  }

  async snapBanners(callback, location = null) {
    let bannersQ = query(collection(this.db, 'AdvertisingArea'));
    let userData;
    let hasUserLogged = false;
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      userData = await this.userHelper.getUser();
      if (userData) {
        hasUserLogged = true
      }
    }

    let unsub = onSnapshot(bannersQ, async (snapshot) => {
      let banners = this.getList(snapshot);

      if (banners?.length) {

        if (location) {
          banners = banners
            .filter(banner => banner.Location__c === location)
        } else {
          banners = banners
            .filter(banner => !hasUserLogged ? banner.Location__c === 'Home Deslogada' : banner.Location__c === 'Home Logada')
            ?.filter(banner => banner.acg_App__c === 'Ambos' || banner.acg_App__c === 'Acessa Agro')
        }

        // if has user logged
        if (hasUserLogged) {
          let newInfo = banners?.reduce((a, b) => {
            a.push(b.Id)
            return a
          }, []).join(';')

          const result = await this.getGenericInfo(
            "cgny2__AdvertisingArea__c",
            "cgny2__TargetGroup__c",
            "Id",
            userData?.Id,
            "Id",
            newInfo,
            null
          )

          let bannersToReturn = []
          if (result.length) {
            let validResult = result.filter(x => x.valid)
            if (validResult && validResult.length && banners?.filter(x => x.Id === validResult[0].value)) {
              bannersToReturn = banners.reduce((group, banner) => {
                let check = validResult.filter(x => x.value === banner.Id)
                group = check?.length > 0 ? [...group, banner] : [...group]
                return group
              }, []);
            }
            banners = bannersToReturn
          }
        }
      }

      callback(banners);
    })

    return { banners: unsub }
  }

  snapRewards(callback) {
    let rewardsQ = query(collection(this.db, 'Reward'), orderBy('Points__c', 'desc'));

    let unsub = onSnapshot(rewardsQ, (snapshot) => {
      let rewards = this.getList(snapshot);
      callback(rewards);
    })

    return { rewards: unsub }
  }
  snapNews(callback) {
    let newsQ = query(collection(this.db, 'News'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { news: unsub }
  }
  snapYTVideos(callback) {
    let newsQ = query(collection(this.db, 'Youtube'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { ytvideos: unsub }
  }
  snapTrainings(callback) {
    let newsQ = query(collection(this.db, 'Training'), orderBy('PublishDate__c', 'desc'));

    let unsub = onSnapshot(newsQ, (snapshot) => {
      let news = this.getList(snapshot);
      callback(news);
    })

    return { trainings: unsub }
  }
  snapConfig(config, callback) {
    let configQ = query(collection(this.db, config.table));

    if (config.where?.length) {
      config.where.map(condition => {
        configQ = query(configQ, where(condition[0], condition[1], condition[2]))
      })
    }

    if (config.order?.length) {
      config.order.map(order => {
        configQ = query(configQ, orderBy(order[0], order[1]))
      })
    }

    let unsub = onSnapshot(configQ, (snapshot) => {
      let list = this.getList(snapshot);
      callback(list);
    })

    return unsub
  }
  async getNews(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'News'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getYTVideos(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'Youtube'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }
  async getTrainings(qty = 10, startAfterDoc = null) {
    let newsQ = query(collection(this.db, 'Training'), orderBy('PublishDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      newsQ = query(newsQ, startAfter(startAfterDoc));
    }
    let newsDoc = await getDocs(newsQ);

    let data = [];
    let lastDoc = null;

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  async getSurveys(isQuiz, qty = 10, startAfterDoc = null) {

    let elQ = query(collection(this.db, isQuiz ? 'Quiz' : 'Survey'), orderBy('Order__c', 'asc'), limit(qty));
    if (startAfterDoc) {
      elQ = query(elQ, startAfter(startAfterDoc));
    }
    let elDoc = await getDocs(elQ);

    let data = [];
    let lastDoc = null;

    if (elDoc.docs.length) {
      data = elDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  async getArticle(articleId) {
    let firebaseId = articleId.substr(0, 15);
    let articleQ = doc(this.db, 'News', firebaseId);
    let articleDoc = await getDoc(articleQ);

    if (articleDoc?.exists()) {
      return articleDoc.data();
    }

    return false;
  }

  async getSurvey(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let surveyQ = doc(this.db, category, firebaseId);
    let surveyDoc = await getDoc(surveyQ);

    if (surveyDoc?.exists()) {
      return surveyDoc.data();
    }

    return false;
  }

  async getSurveyQuestions(surveyId, category = 'Survey') {
    if (!surveyId) return false;

    let firebaseId = surveyId.substr(0, 15);
    let questionsQ = query(collection(this.db, '/' + category + '/' + firebaseId + '/Question/'), orderBy('Order__c'));
    let questionsDoc = await getDocs(questionsQ);
    let data = [];

    if (questionsDoc.docs.length) {
      data = questionsDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }

  async getAppSettingText(textName, callback) {
    let textQ = doc(this.db, 'AppSetting', textName);
    let textDoc = await getDoc(textQ);

    if (textDoc?.exists()) {
      callback(textDoc.data()['TextValue__c'] || textDoc.data()['HtmlValue__c']);
    }
    else {
      callback('');
    }
  }

  getTermCompiledText(docData) {
    let text = '';

    let fields = [
      'acg_PdfUrl__c'
    ]

    if (!docData) {
      return text;
    }

    text = fields.reduce((acc, v) => acc + (docData[v] || ''), text)

    return text;
  }

  async getPrivacyText(callback) {
    let termsQ = query(
      collection(this.db, 'AcceptanceTermVersion')
      , where('acg_IsActive__c', '==', true)
      , where('acg_AcceptanceTerm__r.acg_IsPrivate__c', '==', true)
    );
    let termsDoc = await getDocs(termsQ);

    let text = 'Política de Privacidade indisponível'

    if (termsDoc.docs.length) {
      for (let i in termsDoc.docs) {
        let docData = termsDoc.docs[i].data();

        text = this.getTermCompiledText(docData);
      }
    }

    callback(text);
  }

  async getTermsText(callback) {
    this.getAppSettingText('TermsOfService', callback);
  }

  async getHerokuDomainApi() {
    let urlQ = doc(this.db, 'AppSetting', 'HerokuApi');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      const { url, token } = JSON.parse(urlDoc.data().TextValue__c || '{}')

      return { url, token };
    }

    return false;

  }

  async getHerokuDomain() {
    if (global.isTestRunning) return process.env.REACT_APP_LOCAL_PATH_DEV;
    if (process.env.REACT_APP_RUNNING_IN_LOCAL === 'TRUE') {
      return process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_LOCAL_PATH_DEV : process.env.REACT_APP_LOCAL_PATH_UAT
    }

    let urlQ = doc(this.db, 'AppSetting', 'HerokuUrl');
    let urlDoc = await getDoc(urlQ);

    if (urlDoc.exists() && urlDoc.data().TextValue__c) {
      return `${urlDoc.data().TextValue__c}/v2`;
    }

    return false;
  }

  getHerokuUrl(path, isRest = true) {
    let url = '';

    if (isRest) {
      url += '/restHelper';
    }

    url += path;

    return url;
  }

  async loadCurrentSeasonId() {
    let seasonQ = doc(this.db, 'AppSetting', 'CurrentSeason');
    let seasonDoc = await getDoc(seasonQ);

    let season = '';

    if (seasonDoc.exists() && seasonDoc.data().TextValue__c) {
      season = JSON.parse(seasonDoc.data().TextValue__c)?.Id;
    }

    global.season = season;

    return season;
  }

  async getFocusedExtractTransactions(cpfCnpj, type) {
    if (!cpfCnpj || !type) return false;

    try {
      return this.api.post(this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_EXTRACTRANSACTIONS_URL, false), { cpfCnpj: cpfCnpj, type: type })
    } catch (e) {
      console.log('getFocusedExtractTransactions', e);
      return false;
    }
  }

  async getFocusedAnalystRelantionshipTree(FirebaseId) {
    if (!FirebaseId) return false;

    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_RELATIONSHIP_URL, false), { userId: FirebaseId })
      return response ?? [];
    } catch (e) {
      console.log('getFocusedAnalystRelantionshipTree', e);
      return false;
    }
  }

  async getFocusedAnalystAccepted(FirebaseId) {
    if (!FirebaseId) return false;

    try {
      return await this.api.post(this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_ACCEPTED_URL, false), { cpf: FirebaseId })
    } catch (e) {
      console.log('getFocusedAnalystAccepted', e);
      return false;
    }
  }

  async getFocusedAnalystData(cpf) {
    if (!cpf) return false;

    try {
      return await this.api.post(this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_CUSTOMERS_URL, false), { cpf: cpf })
    } catch (e) {
      console.log('getFocusedAnalystData', e)
      return false;
    }
  }

  async getFocusedAnalystConsumptionData(cpf) {
    if (!cpf) return false;

    try {
      return await this.api.post(this.getHerokuUrl(HEROKU_FOCUSED_ANALYST_CONSUPTION_URL, false), { cpf: cpf })
    } catch (e) {
      console.log('getFocusedAnalystConsumptionData', e)
      return false;
    }
  }

  async getRewardFilters() {
    let filtersQ = doc(this.db, 'AppSetting', 'FilterProductOptions');
    let filtersDoc = await getDoc(filtersQ);

    let filters = [];

    if (filtersDoc?.exists() && filtersDoc?.data()['TextValue__c']) {
      filters = JSON.parse(filtersDoc.data()['TextValue__c']);
    }

    return filters;
  }

  async getCaseTypes(callback) {
    let user = this.userHelper.getAuthUser();

    let casesQ, casesDoc;

    if (!user) {
      casesQ = doc(this.db, 'AppSetting', 'CaseSettingsUnlogged');
      casesDoc = await getDoc(casesQ);
    }

    if (!casesDoc?.exists()) {
      casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
      casesDoc = await getDoc(casesQ);
    }

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      if (objTextValue.types) {
        callback(objTextValue.types);
        return true;
      }
    }

    callback([]);
    return false;
  }

  async getCaseRecordTypeId() {
    let casesQ = doc(this.db, 'AppSetting', 'CaseSettings');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      let objTextValue = JSON.parse(casesDoc.data()['TextValue__c']);
      return objTextValue.recordType;
    }

    return false;
  }

  async uploadImage(img, path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    const snapshot = await uploadBytes(storageRef, img, metadata);
    const url = await getDownloadURL(storageRef);

    return url;
  }

  async deleteImage(path, imageId) {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${imageId}.jpeg`);

    try {
      await deleteObject(storageRef);
      return true;
    }
    catch (e) {
      console.log('erro deletando imagem!', e);
      return false;
    }
  }

  async getAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let accountQ = query(collection(this.db, 'Account'), where('acg_TaxId__c', '==', cnpj));
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length)
      return data[0]

    return false;
  }

  // Uma conta raíz é aquela que possui os 8 primeiros dígitos iguais às suas contas filhas
  // e possui ParentIDCRM__c === null (ou sequer possui tal parâmetro)
  // Além disso, a conta deve ser elegível para registrar NFs (acg_ElegivelNF__c)
  async getParentAccountByCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    let rootCnpj = cnpj.slice(0, 8);
    let accountQ = query(
      collection(this.db, 'Account'),
      where('acg_TaxIdStarted__c', '==', rootCnpj),
      where('acg_ElegivelNF__c', '==', true)
    );
    let cnpjDoc = await getDocs(accountQ);

    let data = this.docsToArray(cnpjDoc);

    if (data.length) {
      let result = false;

      data.map(item => {
        if (!item.ParentIDCRM__c) {
          result = item;
        }
      })
      console.log('result', result)
      return result;
    }

    return false;
  }

  async getTermVersion(id) {
    let termQ = doc(this.db, 'AcceptanceTermVersion', id)
    let termDoc = await getDoc(termQ);

    if (termDoc?.exists()) {
      let termData = termDoc.data();

      if (termData.acg_IsActive__c) {
        return termData;
      }
    }

    return false;
  }

  parseTermsForCheckbox(terms, termVersionPath) {
    let parsedTerms = terms?.map(term => {
      let parsedTerm = { ...term };
      let label = 'Aceito os termos de uso';

      if (term.acg_AcceptanceTerm__r?.acg_Label__c) {
        label = term.acg_AcceptanceTerm__r?.acg_Label__c;
        let hashcount = (label.match(/##/g) || []).length;
        if (hashcount === 2) {
          let link = termVersionPath.replace(':id', term.Id.slice(0, 15))
          label = label.replace(/##/, '<a href="' + link + '" target="_blank">')
          label = label.replace(/##/, '</a>')
        }
      }

      parsedTerm.input = {
        label,
        error: term?.errorMessage || '',
      }

      return parsedTerm;
    })

    return parsedTerms
  }

  async getInvoiceSyngentaTotal(invoiceId) {
    if (!invoiceId) return false;

    const data = {
      invoiceId,
    }

    try {
      const response = await this.api.get(`${this.getHerokuUrl(HEROKU_INVOICE_SYNGENTA_TOTAL_URL)}?${new URLSearchParams(data)}`)
      if (response?.success) {
        return response.total;
      }
    }
    catch (e) {
      console.log('getInvoiceSyngentaTotal', e)
    }

    return 0;
  }

  async getAgrolinkData(params) {
    try {
      const response = await this.api.get(`${this.getHerokuUrl(HEROKU_AGROLINK_URL, false)}?${new URLSearchParams(params)}`)

      if (response?.success) {
        return response.data;
      }
    }
    catch (e) {
      console.log('getAgrolinkData', e)
    }

    return false;
  }

  async saveLog({ method, data, result }) {
    const removeEmpty = (obj) => {
      let newObj = {};
      Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
        else if (obj[key] !== undefined) newObj[key] = obj[key];
      });
      return newObj;
    };

    let log = {
      date: serverTimestamp(),
      source: 'site',
      currentUrl: window.location.href,
      method,
      data,
      result: result?.message ? result.message : (JSON.stringify(result) ? JSON.stringify(result) : result),
    }

    log = removeEmpty(log);

    await addDoc(collection(this.db, 'Logs'), log)
  }

  dateDisplayFormat(date) {
    if (date?.length && date?.search('-') !== -1) {
      return date.split('-').reverse().join('/')
    }

    return date;
  }

  appScreenToUrl(routes, appScreen) {
    switch (appScreen) {
      case 'Profile':
        return routes.profile.path;
      case 'Survey':
        return routes.survey.path;
      case 'ReceiptHistory':
        return routes.home.path;
      default:
        return routes.home.path;
    }
  }

  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  docsToArray(elDocs) {
    let data = [];

    if (elDocs.docs.length) {
      data = elDocs.docs.map((doc) => {
        return doc.data();
      })
    }

    return data;
  }

  async getRafflesProdutividadeIds() {
    let casesQ = doc(this.db, 'AppSetting', 'RafflesProdutividade');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      return casesDoc.data()['TextValue__c']
    }

    return false;
  }

  async getRafflesProdutividadeConsultorIds() {
    let casesQ = doc(this.db, 'AppSetting', 'RafflesProdutividadeConsultor');
    let casesDoc = await getDoc(casesQ);

    if (casesDoc?.exists() && casesDoc?.data()['TextValue__c']) {
      return casesDoc.data()['TextValue__c']
    }

    return false;
  }

  async getConsultantPerformance(pointId) {
    if (!pointId) {
      return false;
    }

    try {
      return await this.api.post(this.getHerokuUrl(`${HEROKU_GET_PERFORMANCE_URL}Consultant`, false), { pointId })
    } catch (e) {
      return false;
    }
  }

  async getBalance(idContact) {
    if (!idContact) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_BALANCE_TRANSFER_POINTS_URL}/${idContact}`, false))
      return response?.Balance && response?.Balance > 0 ? response?.Balance : 0;
    }
    catch (e) {
      console.log('getBalance', e);
      return false;
    };
  };

  userByDoc = async (doc) => {
    if (!doc) return false;

    try {
      return await this.api.get(this.getHerokuUrl(`${HEROKU_GET_USER_BY_DOC}/${doc}`, false))
    }
    catch (e) {
      console.log('userByDoc', e);
      return { message: 'erro ao trazer usuário!', error: true }
    };
  }

  completedTransfer = async (payload) => {
    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_POST_COMPLETED_TRANSFER, false), { ...payload })

      if (response?.message) {
        return { success: true, message: response?.message };
      } else {
        return { success: false, message: 'algo deu errado com parse!' }
      }
    } catch (e) {
      console.log('completedTransfer', e)
      return false;
    }
  }

  async getFavoriteAccounts(firebaseId) {
    if (!firebaseId) return false;

    try {
      return await this.api.get(this.getHerokuUrl(`${HEROKU_FAVORITE_ACCOUNTS}/${firebaseId}`, false));
    } catch (e) {
      console.log('getFavoriteAccounts', e)
      return false;
    }
  }

  async validatePointGroupUser(firebaseId) {
    if (!firebaseId) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_VALIDATE_USER}/${firebaseId}`, false));
      return response;
    } catch (error) {
      console.log('validateUserUrl', error)
      return false;
    }
  }

  async createPointGroup(groupData) {
    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_CREATE_POINT_GROUP, false), { ...groupData })
      return response;
    } catch (e) {
      console.log('createPointGroup', e)
      return false;
    }
  }

  async addAndInvite(leaderFirebaseId, participantFirebaseId, entryRequest = false) {
    if (!leaderFirebaseId || !participantFirebaseId) return false;

    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_ADD_AND_INVITE, false), { leaderFirebaseId, participantFirebaseId }, true, (entryRequest && { 'Type': 'entry-request' }))
      return response;
    } catch (error) {
      return false
    }
  }

  async isInAGroup(firebaseId) {
    if (!firebaseId) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_IS_IN_A_GROUP}/${firebaseId}`, false));
      return response;
    } catch (error) {
      console.log('isInAGroup', error)
      return false
    }
  }

  async getInvites(firebaseId, type = "Group") {
    if (!firebaseId) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_INVITES}/${firebaseId}`, false), true, { 'Type': type });
      return response;
    } catch (error) {
      console.log('getInvites', error)
      return false
    }
  }

  async acceptInvite(firebaseId, inviteId, type) {
    if (!firebaseId || !inviteId || !type) return false;

    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_ACCEPT_INVITE, false), { firebaseId, inviteId }, true, { 'Type': type })
      return response;
    } catch (error) {
      console.log('acceptInvite', error)
      return false
    }
  }

  async rejectInvite(firebaseId, inviteId, type) {
    if (!firebaseId || !inviteId || !type) return false;

    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_REJECT_INVITE, false), { firebaseId, inviteId }, true, { 'Type': type })
      return response;
    } catch (error) {
      console.log('rejectInvite', error)
      return false
    }
  }

  async searchContact(leaderFirebaseId, participantCpfCnpj) {
    if (!leaderFirebaseId || !participantCpfCnpj) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_SEARCH_CONTACT}/${leaderFirebaseId}/${participantCpfCnpj}`, false));
      return response;
    } catch (error) {
      console.log('searchContact', error)
      return false
    }
  }

  async getGroupData(firebaseId) {
    if (!firebaseId) return false;

    try {
      const response = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_GROUP_DATA}/${firebaseId}`, false));
      return response;
    } catch (error) {
      console.log('getGroupData', error)
      return false
    }
  }

  async inviteContact(leaderFirebaseId, participantFirebaseId, type) {
    if (!leaderFirebaseId || !participantFirebaseId || !type) return false;

    try {
      const response = await this.api.post(this.getHerokuUrl(HEROKU_INVITE_CONTACT, false), { leaderFirebaseId, participantFirebaseId }, true, { 'Type': type })
      return response;
    } catch (error) {
      console.log('inviteContact', error)
      return false
    }
  }

  async changeGroupName(name, id) {
    if (!name || !id) return false;

    try {
      const data = await this.api.put(this.getHerokuUrl(HEROKU_CHANGE_GROUP_NAME, false), { name, id })
      return data;
    } catch (error) {
      console.log('changeGroupName', error)
      return false
    }
  }

  async leaveGroup(firebaseId) {
    if (!firebaseId) return false;

    try {
      const data = await this.api.post(this.getHerokuUrl(`${HEROKU_LEAVE_GROUP}/${firebaseId}`, false))
      return data;
    } catch (error) {
      console.log('leaveGroup', error)
      return false
    }
  }

  async removeFromGroup(leaderFirebaseId, participantFirebaseId) {
    if (!leaderFirebaseId || !participantFirebaseId) return false;

    try {
      const data = await this.api.post(this.getHerokuUrl(HEROKU_REMOVE_FROM_GROUP, false), { leaderFirebaseId, participantFirebaseId })
      return data;
    } catch (error) {
      console.log('removeFromGroup', error)
      return false
    }
  }

  async endGroup(firebaseId) {
    if (!firebaseId) return false;

    try {
      const data = await this.api.post(this.getHerokuUrl(`${HEROKU_END_GROUP}/${firebaseId}`, false))
      return data;
    } catch (error) {
      console.log('endGroup', error)
      return false
    }
  }

  async promoteToLeader(leaderFirebaseId, participantFirebaseId) {
    if (!leaderFirebaseId || !participantFirebaseId) return false;

    try {
      const data = await this.api.post(this.getHerokuUrl(HEROKU_PROMOTE_TO_LEADER, false), { leaderFirebaseId, participantFirebaseId })
      return data;
    } catch (error) {
      console.log('promoteToLeader', error)
      return false
    }
  }

  async getRegulations(userId) {
    if (!userId) {
      return false;
    };

    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_REGULATIONS}/${userId}`, false));
      return data;
    } catch (error) {
      console.log('getRegulations', error)
      return false
    }
  }

  async getRecordTypes(developerName = 'WelcomeContact') {
    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_RECORD_TYPE}/${developerName}`, false));
      return data;
    } catch (error) {
      console.log('getRecordTypes', error)
      return false
    }
  }

  async getProfilesByUserGroup(userGroupId) {
    if (!userGroupId) {
      return false;
    }


    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_PROFILES_BY_USER_GROUP}/${userGroupId}`, false));
      return data;
    } catch (error) {
      console.log('getProfilesByUserGroup', error)
      return false
    }
  }

  async hasActiveProfiles(userGroupId) {
    if (!userGroupId) {
      return false;
    }

    const profiles = await this.getProfilesByUserGroup(userGroupId);

    if (profiles && profiles?.length) {
      return profiles.some((u) => !["Inativo", "Blocked"].includes(u.acg_Status__c))
    }

    return false;
  }

  async getPerformanceSalesTeam(status, channel, businessCycleId) {
    if (!status || !channel || !businessCycleId) {
      return false;
    }
    const { url: herokuApi, token } = await this.getHerokuDomainApi();
    const url = `${HEROKU_GET_PERFORMANCE_SALES_TEAM_URL}${status === "Pontuação liberada" ? "_aprovado?" : "?"}p_canal=${channel}&p_business_cycle_id=${businessCycleId}`;
    const performanceSalesTeamUrl = `${herokuApi}${url}`

    try {
      const data = await this.api.get(performanceSalesTeamUrl);
      return data;
    } catch (error) {
      console.log('getPerformanceSalesTeam', error)
      return false
    }
  }

  async getPerformanceSalesTeamAnticipation(status, anticipationId) {
    if (!status || !anticipationId) {
      return false;
    }

    const { url: herokuApi, token } = await this.getHerokuDomainApi();
    const url = `${HEROKU_GET_PERFORMANCE_SALES_TEAM_ANTICIPATION_URL}${status === "Aprovado" ? "_aprovado?" : "?"}p_anticipation_id=${anticipationId}`
    const performanceSalesTeamAnticipationUrl = `${herokuApi}${url}`

    try {
      const data = await this.api.get(performanceSalesTeamAnticipationUrl);
      return data;
    } catch (error) {
      console.log('getPerformanceSalesTeamAnticipation', error)
      return false
    }
  }

  async getDistributors(limit, page) {
    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_DISTRIBUTORS_URL}/noInvoce?limit=${limit}&page=${page}`, true));
      return data;
    } catch (e) {
      console.log('getDistributors', e)
      return false;
    }
  }

  async getCanaisSolicitantes() {
    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_DISTRIBUTORS_URL}/isInvoice`));
      return data;
    } catch (error) {
      console.log('getCanaisSolicitantes', error)
      return false
    }
  }

  async getUserInfoBeforeLogin(firebaseId) {
    if (!firebaseId) return false;

    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_USER_INFO_BEFORE_LOGIN}/${firebaseId}`, false));
      return data;
    } catch (error) {
      console.log('getUserInfoBeforeLogin', error)
      return false
    }
  }

  async removeFavoriteAccount(mainContactFirebaseId, participantFirebaseId) {
    if (!mainContactFirebaseId || !participantFirebaseId) return false;

    try {
      const data = await this.api.post(this.getHerokuUrl(HEROKU_REMOVE_FAVORITE_ACCOUNT, false), { mainContactFirebaseId, participantFirebaseId })
      return data;
    } catch (error) {
      return false
    }
  }

  async snapStartUpMessages(callback) {
    let startupMessageQ = query(collection(this.db, 'StartUpMessages'), orderBy('Priority__c', 'asc'));

    let unsub = onSnapshot(startupMessageQ, async (snapshot) => {
      let startupMessages = this.getList(snapshot);
      let lst = []
      const actualDate = moment(new Date(), 'YYYY-MM-DD HH:mm')
      if (startupMessages?.length) {
        for (let i = 0; i < startupMessages.length; i++) {
          const startDate = moment(startupMessages[i].StartDateTime__c, "YYYY-MM-DDTHH:mm").subtract(3, "hours");
          const endDate = moment(startupMessages[i].EndDateTime__c, "YYYY-MM-DDTHH:mm").subtract(3, "hours");
          if (actualDate >= startDate && actualDate < endDate) {
            lst.push(startupMessages[i]);
          }
        }
      }

      callback(lst);
    })

    return { startupMessages: unsub }
  }

  async groupHistory(inviteId) {
    if (!inviteId) return false;

    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GROUP_HISTORY}/${inviteId}`, false));
      return data;
    } catch (error) {
      console.log('groupHistoryUrl', error)
      return false
    }
  }

  async getCampaigns(firebaseId) {
    try {
      return await this.api.get(this.getHerokuUrl(`${HEROKU_CAMPAIGNS}/${firebaseId}`, false))
    } catch (error) {
      console.log('getCampaigns', error)
      return false
    }
  }

  async enrollCampaign(campaignId, firebaseId, clientIP, acceptanceDevice) {
    const body = {
      ip: clientIP,
      device: acceptanceDevice
    }

    try {
      const data = await this.api.post(this.getHerokuUrl(`${HEROKU_CAMPAIGNS_ENROLLMENT}/${campaignId}/${firebaseId}`, false), body, true, { 'Access-Control-Allow-Origin': '*' })
      return data;
    } catch (error) {
      console.log('enrollCampaign', error)
      return false
    }
  }

  async getCampaignsResults(campaignId, firebaseId) {
    if (!campaignId || !firebaseId) return false;
    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_CAMPAIGNS_RESULTS}/${campaignId}/${firebaseId}`, false));
      return data;
    } catch (error) {
      console.log('getCampaignsResults', error)
      return false
    }
  }

  async getCampaignsMyNumbers(campaignId, firebaseId) {
    if (!campaignId || !firebaseId) return false;

    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_CAMPAIGNS_MY_NUMBERS}/${campaignId}/${firebaseId}`, false));
      return data;
    } catch (error) {
      console.log('getCampaignsMyNumbers', error)
      return false
    }
  }

  async getAboutProgramSweepstake() {
    let Q = query(collection(this.db, 'Sweepstake'));
    let QDocs = await getDocs(Q);

    let data = [];

    if (QDocs.docs.length) {
      data = QDocs.docs.map((doc) => doc.data());
    }

    return data;
  }

  async getAboutProgramData() {
    let Q = doc(this.db, 'AppSetting', "AboutProgram");
    let QDoc = await getDoc(Q);

    const docs = await this.getAboutProgramSweepstake();

    const data = {};

    if (QDoc.exists()) {
      const { HtmlValue__c, TextValue__c } = QDoc.data();
      data.html = HtmlValue__c;
      data.image = TextValue__c;
      data.docs = docs
    }

    return data;
  }

  async validateSweepstake(id, docs) {
    const response = await this.verifySweepstakeIds(id, docs, "Product2");

    const validDocs = [];

    if (response?.length > 0) {
      response.forEach((response) => {
        if (response.valid) {
          const doc = docs.find((d) => d.Id === response.value);
          validDocs.push({ ...doc, valid: true })
        }
      });

      return validDocs.sort((a, b) => a?.Order__c - b?.Order__c);
    }

    return [];
  }

  async verifySweepstakeIds(id, docs, objectName) {
    const body = {
      objectName,
      targetGroupField: "acg_TargetGroup__c",
      filterField: "Id",
      contactId: id,
      messageField: "",
      items: docs?.map((d) => ({ value: d.Id }))
    }

    try {
      const data = await this.api.post(this.getHerokuUrl(HEROKU_VERIFY_SWEEPSTAKE_IDS, false), body)
      return data;
    } catch (error) {
      return []
    }
  }

  async getGenericInfo(objectName, targetGroupField, filterField, contactFieldValue, contactField, target, messageField) {
    const body = messageField
      ? { objectName, targetGroupField, filterField, contactFieldValue, contactField, target, messageField }
      : { objectName, targetGroupField, filterField, contactFieldValue, contactField, target }

    try {
      const response = await this.api.post(this.getHerokuUrl(`${HEROKU_GENERIC_INFO}`, false), body)
      return response
    } catch (error) {
      console.log('getGenericInfo', error)
      return false
    }
  }

  async getUserByReferralCode(code) {
    if (!code) return;

    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_USER_REFERRAL_CODE}/${code}`, false));
      return data;
    } catch (error) {
      console.log('getUserByReferralCode', error)
      return false
    }
  }

  async getAboutCluster(loyaltyProfile) {
    let queryRef = collection(this.db, 'AboutCluster');

    if (loyaltyProfile) {
      queryRef = query(queryRef, where('acg_LoyaltyProfile__c', '==', loyaltyProfile));
    }

    const querySnapshot = await getDocs(queryRef);

    let data = {};
    const clusters = []

    querySnapshot.forEach((doc) => {
      if (doc.exists()) {

        if (loyaltyProfile) {
          data = { id: doc.id, ...doc.data() }
        } else {
          clusters.push({
            Id: doc.data().Id,
            name: doc.data().acg_LoyaltyProfile__c,
            description: doc.data().acg_Description__c,
            ...doc.data(),
          })
        }

      }
    });

    return loyaltyProfile ? data : clusters;
  }


  async getAboutItemAssignment(clusterIds) {
    const list = [];

    for (const { id, name, description, order, ...rest } of clusterIds) {

      const validItems = []

      const querySnapshot = await getDocs(query(collection(this.db, "AboutCluster"), where('Id', '==', id)))

      if (querySnapshot.empty) {
        continue
      }

      for (const doc of querySnapshot.docs) {

        const itemSnapshot = await getDocs(query(collection(doc.ref, 'AboutItemAssignment'), where('acg_IsActive__c', '==', true)));

        validItems.push(...itemSnapshot.docs.map((subDoc) => subDoc.data().acg_AboutItem__c));

        list.push({
          cluster: { id, name, description, order, ...rest },
          validItems,
        })

      }
    }

    return list;
  }

  async getAboutItemData(payload) {
    const list = [];

    for (const { cluster: { id, name, description, order, ...rest }, validItems } of payload) {

      const querySnapshot = await getDocs(query(collection(this.db, 'AboutItem')))

      if (querySnapshot.empty) {
        continue;
      }

      let data = { id, name, description, order, ...rest, items: [] };

      for (const validItemId of validItems) {

        querySnapshot.docs.forEach((doc) => {
          if (doc.data().Id === validItemId) {
            data.items.push({
              benefitType: doc.data().acg_BenefitType__c,
              imageUrl: doc.data().acg_ImageUrl__c,
              redirectUrl: doc.data().acg_RedirectUrl__c,
              text: doc.data().acg_Text__c,
              tooltip: doc.data().acg_TextTooltip__c,
              ...doc.data(),
            })
          }
        })
      }

      list.push(data);
    }

    return list;
  }

  async getClusterItems(id) {
    const orders = {
      "OTO Silver": 1,
      "OTO Black": 2,
      "OTO Royal": 3
    }

    const ids = await this.getAboutCluster();
    const verifiedIds = await this.verifySweepstakeIds(id, ids, "acg_About__c");
    const validIds = verifiedIds
      .filter((v) => v.valid)
      .map((d) => {
        const { name, description, ...rest } = ids.find((i) => i.Id === d.value)
        return {
          id: d.value,
          name: name,
          description,
          order: orders[name],
          ...rest,
        }
      });

    const data = await this.getAboutItemAssignment(validIds);
    const associatedItems = await this.getAboutItemData(data);
    return associatedItems.sort((a, b) => a.order - b.order);
  }

  async getAcessaPlusPartialConsumption(cpfCnpj) {
    if (!cpfCnpj) return false

    let response
    try {
      response = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_ACESSA_PLUS_PARTIAL_CONSUMPTION}/${cpfCnpj}`, false));
      return response
    } catch (error) {
      return false
    }
  }

  async getParticipantLoans(cpfCnpj) {
    try {
      const data = await this.api.get(this.getHerokuUrl(`${HEROKU_GET_PARTICIPANT_LOANS}/${cpfCnpj}`, false));
      return data;
    } catch (error) {
      return false;
    }
  }

  async shortenUrl(url) {
    if (!url) return false;

    try {
      const data = await this.api.post('/shorten', { url })
      return data;
    } catch (error) {
      console.log('shortenURL -->', error)
      return false
    }
  }
}